import React from 'react';
import { useThree } from '@react-three/fiber';
import { Center, Text3D } from '@react-three/drei';
import '../../components/Three/styles.css';
import font from '../../assets/fonts/Poppins_Regular.json';

export function Caption3D({ children }) {
  const { width } = useThree((state) => state.viewport);

  return (
    <Center>
      <Text3D
        bevelEnabled
        bevelSize={0.02}
        bevelThickness={0.1}
        height={0.05}
        lineHeight={0.15}
        letterSpacing={-0.01}
        size={width / 9}
        font={font}
      >
        {children}
        <meshNormalMaterial />
      </Text3D>
    </Center>
  );
}