import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { portifolioMock } from '../../mocks/portifolioMock';
import { GridStyled } from '../../assets/styles';
import { Portifolio } from '../../components/Portifolio';

export function PortifolioPage() {
  const { t } = useTranslation();
  const { data } = portifolioMock;

  return (
    <GridStyled container>
      <Container maxWidth="xl">
        <Grid item xs={12}>
          <Typography variant="h2">{t('meu_portfolio')}</Typography>
        </Grid>
        <Grid item xs={12} sx={{ pt: 4 }}>
          <Grid container spacing={4}>
            {data.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Portifolio item={item} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </GridStyled>
  );
}