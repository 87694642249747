import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Container, ListItem, ListItemText, Typography } from '@mui/material';

import { AppBar, Toolbar, Logo, ListStyled } from './styles';
// import { LanguageSelect } from '../LanguageSelect';

export function Navbar() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  function goToHomePage() {
    navigate('/');
  }

  return (
    <AppBar position="sticky" elevation={0}>
      <Box position="absolute" top="0" left="0" width="100%">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Logo variant="h3" onClick={goToHomePage}>JHØN</Logo>

            <ListStyled>
              {/* <ListItem disableGutters>
                <LanguageSelect />
              </ListItem> */}

              <ListItem disableGutters component={Link} to="/about" sx={{ margin: '0 3rem' }}>
                <ListItemText primary={
                  <Typography variant="menu">{t('sobre')}</Typography>
                }/>
              </ListItem>

              <ListItem disableGutters component={Link} to="/my-portifolio">
                <ListItemText primary={
                  <Typography variant="menu">{t('portifolio')}</Typography>
                }/>
              </ListItem>
            </ListStyled>
          </Toolbar>
        </Container>
      </Box>
    </AppBar>
  );
}