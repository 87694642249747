export const skillsMock = [
  { _id: `${Math.random()}`, name: 'HTML5' },
  { _id: `${Math.random()}`, name: 'CSS3' },
  { _id: `${Math.random()}`, name: 'JavaScript' },
  { _id: `${Math.random()}`, name: 'Typescript' },
  { _id: `${Math.random()}`, name: 'React' },
  { _id: `${Math.random()}`, name: 'React Native' },
  { _id: `${Math.random()}`, name: 'React Hooks' },
  { _id: `${Math.random()}`, name: 'React Router' },
  { _id: `${Math.random()}`, name: 'React Query' },
  { _id: `${Math.random()}`, name: 'Redux' },
  { _id: `${Math.random()}`, name: 'Material-UI' },
  { _id: `${Math.random()}`, name: 'Bootstrap' },
  { _id: `${Math.random()}`, name: 'Styled-Components' },
  { _id: `${Math.random()}`, name: 'NodeJs' },
  { _id: `${Math.random()}`, name: 'I18next' },
  { _id: `${Math.random()}`, name: 'Strapi' },
  { _id: `${Math.random()}`, name: 'Photoshop' },
  { _id: `${Math.random()}`, name: 'Figma' },
  { _id: `${Math.random()}`, name: 'Adobe XD' }
];