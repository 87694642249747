import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export  const GridItem = styled(Grid)`
  display: flex;
  align-items: start;
  justify-content: start;
`;

export const Role = styled(Typography)`
  padding-bottom: 1rem;
`;

export const TimeWorked = styled(Typography)`
  padding-bottom: 1rem;
`;

export const City = styled(Typography)`
  padding-bottom: 1rem;
`;

export const DescriptionJob = styled(Typography)`
 padding-bottom: 3rem;
`;