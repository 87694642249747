import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EastIcon from '@mui/icons-material/East';
import CloseIcon from '@mui/icons-material/Close';

import { DialogTitle, DetailsContainer, DetailsItem, ViewUrl, Direct } from './styles';

export function DialogPortfolioDetails({ item, open, onClose }) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <DialogTitle sx={{ pl: 6, pr: 6}}>
        <Typography variant="h3">{item.name}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </DialogTitle>
      <Grid container sx={{ pb: 5 }}>
        <Grid item xs={12}>
          <DetailsContainer sx={{ pl: 6, pr: 6}}>
            <DetailsItem>
              <Typography variant="overline">{t('empresa')}</Typography>
              <Typography variant="h3">{item.name}</Typography>
            </DetailsItem>
            <DetailsItem>
              <Typography variant="overline">{t('funcao')}</Typography>
              <Typography variant="h3">{item.role}</Typography>
            </DetailsItem>
            <DetailsItem>
              <Typography variant="overline">{t('tecnologias')}</Typography>
              <Typography variant="h3">{item.technologies}</Typography>
            </DetailsItem>
            <DetailsItem>
              <Typography variant="overline">Link</Typography>
              <ViewUrl variant="h3">
                <Direct href={item.url} target="_blank">
                  {t('visualizar')}
                </Direct>
                <EastIcon />
              </ViewUrl>
            </DetailsItem>
          </DetailsContainer>
        </Grid>
        <Grid item xs={12} sx={{ pl: 6, pr: 6}}>
          <Typography variant="body1">
            {item.description}
          </Typography>
        </Grid>
      </Grid>
    </Dialog>
  );
}