import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Float } from '@react-three/drei';
import { Stars, Caption3D } from '../../components/Three';

export function HomePage() {
  const { t } = useTranslation();

  return (
    <Canvas camera={{ position: [0, 0, 1] }}>
      <ambientLight intensity={0.4} />
      <Suspense fallback={null}>
        <Stars />
        <Float floatIntensity={2} speed={1}>
          <Caption3D>{ t('ola') }!</Caption3D>
        </Float> 
      </Suspense>
      <OrbitControls 
          enableZoom={false} 
          // enablePan={true} 
          // minPolarAngle={Math.PI / 3} 
          // maxPolarAngle={Math.PI / 3} 
        />
    </Canvas>
  );
}