import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

import { TranslateCourses } from '../../mocks/coursesMock';
import { GridItem, Course, TimeShoool, City, DescriptionCourse } from './styles'; 

export function Courses() {
  const { t } = useTranslation();
  const { data } = TranslateCourses();

  return (
    <>
      {data.map((item, index) => (
        <Box key={index}>
          <Grid container>
            <GridItem item xs={12}>
              <a href={item.url} target="_blank" rel="noreferrer">
                <img src={item.image} alt="img" />
              </a>
              <Box style={{ marginLeft: '1rem' }}>
                <Course variant="h4">
                  {item.name}
                </Course>
                <TimeShoool variant="body1">
                  {item.timeSchool}
                </TimeShoool>
                <City variant="body1">
                  {item.local}
                </City>
                <DescriptionCourse variant="body1">
                  <strong>{t('descricao')}:</strong>{' '}{item.description}
                </DescriptionCourse>
              </Box>
            </GridItem>
          </Grid>
          {index !== (data.length -1) && 
            <Divider style={{ marginBottom: '3rem' }} />
          }
        </Box>
      )).reverse()}
    </>
  ); 
}