import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const Header = styled(Typography)`
  padding-bottom: 3rem;
`;

export const Headline2 = styled(Typography)`
  padding-bottom: 3rem;
`;

export const Headline3 = styled(Typography)`
  padding: 3rem 0 3rem;
`;

export const Body1 = styled(Typography)`
  padding: 0.5rem
`;

export const GridItem = styled(Grid)`
  margin-bottom: 5rem;
`;