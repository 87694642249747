import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme();

export const LightTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1650,
    },
  },
  typography: {
    fontFamily: ['Poppins'],

    h1: {
      fontWeight: 600,
      fontSize: '15em',
      letterSpacing: 1,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '10em',
      }
    },
    h2: {
      fontWeight: 600,
      fontSize: '3em',
      letterSpacing: 1,
      color: '#121212',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: 25,
      }
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.7em',
      color: '#121212',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: 24,
      }
    },
    h4: {
      fontWeight: 600,
      fontSize: '1.3em',
      color: '#121212',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: 16,
      }
    },
    h5: {
      fontWeight: 600,
      fontSize: 18,
      color: '#121212',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: 16,
      }
    },
    body1: {
      fontWeight: 400,
      fontSize: '1.2em',
      letterSpacing: 1,
      color: '#121212',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: 16,
      }
    },
    body2: {
      fontWeight: 400,
      fontSize: 14,
      color: '#121212',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: 12,
      }
    },
    overline: {
      fontWeight: 600,
      fontSize: 13,
      letterSpacing: 3,
      textTransform: 'uppercase',
      color: '#121212',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: 12,
      }
    },
    menu: {
      fontWeight: 400,
      fontSize: 18,
      textTransform: 'lowercase',
      color: '#121212',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: 16,
      }
    },
    skills: {
      fontWeight: 400,
      fontSize: 18,
      letterSpacing: 1,
      color: '#121212',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: 14,
      }
    }
  },
  palette: {
    primary: {
      main: '#90caf9',
      dark: '#42a5f5',
      light: '#e3f2fd',
    },
    secondy: {
      main: '#ce93d8',
      dark: '#ab47bc',
      light: '#f3e5f5',
    },
    background: {
      paper: '#f7f6f3',
      default: '#f7f6f3',
    },
    info: {
      main: '#29b6f6',
      dark: '#0288d1',
      light: '#4fc3f7',
    },
    navbar: {
      main: '#f7f6f3',
    },
    text: {
      main: '#121212',
    },
    neutral: {
      low: {
        main: '#212529',
        dark: '#343A40',
        medium: '#495057',
        light: '#868E96',
        lightest: '#CED4DA',
      },
      high: {
        main: '#FFFFFF',
        dark: '#DEE2E6',
        medium: '#E7EAED',
        light: '#EEF0F2',
        lightest: '#F8F9FA',
      },
    },
  },
});