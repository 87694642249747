import React from 'react';
import { useTranslation } from 'react-i18next';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { GridStyled } from '../../assets/styles';
import { Header, Headline2, Headline3, Body1, GridItem } from './styles';
import { Courses } from '../../components/Courses';
import { Works } from '../../components/Works';
import { Skills } from '../../components/Skills';

export function AboutPage() {
  const { t } = useTranslation();

  return (
    <GridStyled container>
      <Container maxWidth="xl">
        <GridItem item xs={12}>
          <Header variant="h2">{t('sobre_mim')}</Header>
          <Typography variant="body1">{t('minha_descricao')}</Typography>
          <Headline3 variant="h2">{t('competencias')}</Headline3>
          <Skills/>
          <Headline3 variant="h2">{t('idiomas')}</Headline3>
          <Body1 variant="body1"><strong>{t('portugues')}</strong> | {t('fluente')} <br/></Body1>
          <Body1><strong>{t('ingles')}</strong> | {t('intermediario')}</Body1>
        </GridItem>

        <GridItem item xs={12}>
          <Headline2 variant="h2">{t('experiencia')}</Headline2>
          <Works />         
        </GridItem>

        <GridItem item xs={12}>
          <Headline2 variant="h2">{t('formacao')}</Headline2>
          <Courses />    
        </GridItem>
      </Container>
    </GridStyled>
  );
}