import { createContext, useState, useMemo, useContext, useCallback } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { DarkTheme, LightTheme } from '../themes';

export const ThemeModeContext = createContext({
  toggleTheme: () => {},
  mode: 'dark' | 'light'
});

export const useAppThemeContext = () => {
  return useContext(ThemeModeContext);
}

export const ThemeContextProvider = ({ children }) => {
  const [mode, setMode] = useState('dark');

  const toggleTheme = useCallback(() => {
    setMode((prevMode) => (prevMode === 'dark' ? 'light' : 'dark'));
  }, []);
  
  const theme = useMemo(() => {
    if (mode === 'dark') {
      return DarkTheme;
    }
    return LightTheme;
  }, [mode]);

  return (
    <ThemeModeContext.Provider value={{ mode, toggleTheme }}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ThemeModeContext.Provider>
  )
}