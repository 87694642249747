import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { HomePage } from './containers/HomePage';
import { AboutPage } from './containers/AboutPage';
import { PortifolioPage } from './containers/PortifolioPage';

export function MyRoutes() {
  return(
    <Routes>
      <Route exact path="/" element={<HomePage />} />
      <Route exact path="/about" element={<AboutPage />} />
      <Route exact path="/my-portifolio" element={<PortifolioPage />} />
    </Routes>
  );
}