import React, { useState } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

import { DialogPortfolioDetails } from '../../components/DialogPortfolioDetails';

export function Portifolio({ item }) {
  const [openDialog, setOpenDialog] = useState(false);

  function handleOpenDialog() {
    setOpenDialog(!openDialog);
  }
  
  return (
    <>
      <DialogPortfolioDetails open={openDialog} onClose={handleOpenDialog} item={item} />
      <Card onClick={handleOpenDialog}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="140"
            image={item.image}
            alt={item.name}
          />
          <CardContent>
            <Typography variant="h5">{item.name}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
}