import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';

import { MyRoutes } from './routes';
import { Navbar } from './components/Navbar';
import { ThemeContextProvider } from './contexts';
import { MoreButton } from './components/MoreButton';

export default function App() {
  return (
    <ThemeContextProvider>
      <CssBaseline />
      <Router>
        <Navbar />
        <MyRoutes />
        <MoreButton />
      </Router>
    </ThemeContextProvider>
  );
}