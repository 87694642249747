import React from 'react';

import Container from '@mui/material/Container';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import GitHubIcon from '@mui/icons-material/GitHub';
// import PaletteIcon from '@mui/icons-material/Palette';

import { BoxStyled, SpeedDialStyled, Direct } from './styles';
import { useAppThemeContext } from '../../contexts';

export function MoreButton() {
  // const { toggleTheme } = useAppThemeContext();
  const urlWhatsApp = 'https://api.whatsapp.com/send?phone=5511982508239&text=Ol%C3%A1%20Jonatas%2C%20quero%20contratar%20um%20servi%C3%A7o!';
  const urlGitHub = 'https://github.com/jhon-alves';
  const actions = [
    { 
      icon: <Direct href={urlWhatsApp} target="_blank"> <WhatsAppIcon /> </Direct>, 
      name: 'WhatsApp' 
    },
    { 
      icon: <Direct href={urlGitHub} target="_blank"> <GitHubIcon /> </Direct>, 
      name: 'GitHub' 
    },
    // { 
    //   icon: <PaletteIcon onClick={toggleTheme} />, 
    //   name: 'Theme' 
    // },
  ];
  
  return (
    <BoxStyled>
      <Container maxWidth="xl">
        <SpeedDialStyled ariaLabel="SpeedDial" icon={<MoreHorizIcon />}>
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
            />
          ))}
        </SpeedDialStyled>
      </Container>
    </BoxStyled>
  );
}