import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';

export const BoxStyled = styled(Box)`
  position: fixed;
  bottom: 1.5rem;
  right: 0;
  transform: translateZ(0px);
`;

export const SpeedDialStyled = styled(SpeedDial)`
  width: 35px;

  & .MuiSpeedDial-fab {
    width: 35px;
    height: 35px;
    background: linear-gradient(30deg, #c850c0, #ffcc70);
  }
`;

export const Direct = styled('a')(({ theme }) => `
  display: flex;
  justify-content: center;
  color: ${theme.palette.text.main};
`);
