import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

import { TranslateWorks } from '../../mocks/worksMock';
import { GridItem, Role, TimeWorked, City, DescriptionJob } from './styles'; 

export function Works() {
  const { data } = TranslateWorks();
  const { t } = useTranslation();

  return (
    <>
      {data.map((item, index) => (
        <Box key={index}>
          <Grid container>
            <GridItem item xs={12}>
              <Box>
                <a href={item.url} target="_blank">
                  <img src={item.image} alt={item.business} width="70px" height="70px" style={{ borderRadius: '1rem' }} />
                </a>
                <Box display="flex" alingItems="center" justifyContent="start" marginTop="1rem">
                  <LocationOnOutlinedIcon sx={{ fontSize: 20 }} />
                  <City variant="body2">
                    {item.country}
                  </City>
                </Box>
              </Box>
              <Box style={{ marginLeft: '1rem' }}>
                {item.work.map((item, index) => (
                  <Box key={index}>
                    <Role variant="h4">
                      {item.role}
                    </Role>
                    <TimeWorked variant="body1">
                      {item.timeWork}
                    </TimeWorked>
                    <DescriptionJob variant="body1">
                      <strong>{t('descricao')}:</strong>{' '}{item.description}
                    </DescriptionJob>
                  </Box>
                )).reverse()}
              </Box>
            </GridItem>
          </Grid>
          {index !== (data.length -1) && 
            <Divider style={{ marginBottom: '3rem' }} />
          }
        </Box>
      )).reverse()}
    </>
  ); 
}