import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import MuiToolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';

export const AppBar = styled(MuiAppBar)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
`;

export const Toolbar = styled(MuiToolbar)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 75px;
`;

export const Logo = styled(Typography)(({ theme }) => `
  cursor: pointer;
  color: ${theme.palette.text.main};
`);

export const ListStyled = styled(List)`
  display: flex;
  align-items: center;
  padding: 0;
`;