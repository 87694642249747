import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export  const GridItem = styled(Grid)`
  display: flex;
  align-items: start;
  justify-content: start;

  img {
    width: 70px;
    height:70px;
    border-radius: 1rem;
  }
`;

export const Course = styled(Typography)`
  padding-bottom: 1rem;
`;

export const TimeShoool = styled(Typography)`
  padding-bottom: 1rem;
`;

export const City = styled(Typography)`
  padding-bottom: 1rem;
`;

export const DescriptionCourse = styled(Typography)`
 padding-bottom: 3rem;
`;