import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MuiDialogTitle from '@mui/material/DialogTitle';

export const DialogTitle = styled(MuiDialogTitle)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Banner = styled(Grid)`
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
  margin: 0;
`;

export const DetailsContainer = styled(Box)(({ theme }) =>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing(3, 0)};
`);

export const DetailsItem = styled(Box)`
  padding: 1rem;
`;

export const ViewUrl = styled(Typography)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Direct = styled('a')(({ theme }) => `
  text-decoration: none;
  padding-right: 7px;
  color: ${theme.palette.text.main};
`);