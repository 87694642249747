import { useTranslation } from 'react-i18next';
import courseFiap from '../assets/images/course-fiap.jpg';
import courseGamaAcademy from '../assets/images/course-gama-academy.jpg';
import courseJstack from '../assets/images/course-jstack.jpg';

export function TranslateCourses() {
  const { t } = useTranslation();

  const coursesMock = {
    data: [
      {
        _id: '001',
        name: t('desenvolvedor_front_end'),
        school: 'Gama Academy 22',
        description: '',
        timeSchool: 'ago de 2019 - set de 2019',
        local: 'São Paulo, Brasil',
        image: courseGamaAcademy,
        url: 'https://gama.academy/',
      },
      {
        _id: '002',
        name: t('sistemas_para_internet'),
        school: 'FIAP',
        description: t('descricao_fiap'),
        timeSchool: 'fev de 2019 - dez de 2020',
        local: 'São Paulo, Brasil',
        image: courseFiap,
        url: 'https://www.fiap.com.br/',
      },
      {
        _id: '003',
        name: t('desenvolvedor_full_stack'),
        school: 'JStack',
        description: t('descricao_jstack'),
        timeSchool: 'junho de 2022 - o momento',
        local: 'São Paulo, Brasil',
        image: courseJstack,
        url: 'https://jstack.com.br/',
      },
    ]
  }

  return coursesMock;
}